import { LogLevel, LogLevelMap } from './enums';
import { ILogger } from '@sparkware/uc-sdk-core';

export class LoggerFactory {
    public static create(className: string, clientLogger: Console): ILogger {
        function formatMessage(logLevel: LogLevel, ...args: string[]) {
            return `UCF: ${className} : ${LogLevel[logLevel]} : ${args}`;
        }

        return new Proxy<ILogger>(clientLogger, {
            get(_, property) {
                return (message: any, ...args: any) => {
                    const { defaultLogLevel = 'info' } = pageContextData.environment;
                    const logLevel = LogLevelMap[property ?? defaultLogLevel];
                    const formattedMessage =
                        typeof message === 'string' ? formatMessage(logLevel, message) : message;
                    clientLogger[property]?.(formattedMessage, ...(args || []));
                };
            },
        });
    }
}
