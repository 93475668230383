import { Container, Service } from 'typedi';
import { LoggerFactory } from './logger-factory';
import { ILogger } from '@sparkware/uc-sdk-core';
import { TrackJSClientLogger } from './TrackJSClientLogger';
import { ITrackJSClientLogger } from './interfaces/ITrackJSClientLogger';
import { ILoggerProvider } from './interfaces/ILoggerProvider';

@Service()
export class LoggerProvider implements ILoggerProvider {
    private readonly _clientLogger: Console;
    private readonly _trackJSClientLogger: ITrackJSClientLogger;

    constructor() {
        this._trackJSClientLogger = Container.get(TrackJSClientLogger);
        this._clientLogger = this._trackJSClientLogger.getTrackJSLogger();
    }

    public getLogger(className: string): ILogger {
        return LoggerFactory.create(className, this._clientLogger);
    }
}
